import { PageEvents } from "~/enums/tracking/tracking-events.enums";
import { CourseSubViewType } from "~/enums/course.enums";

enum CourseLandingPageSourceValues {
  DiscoveryPage = 'discovery_page',
  CourseLibrary = 'course_library',
  Direct = 'direct',
}

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { user } = useUserStore();
  const { dispatchTrackingEvent } = useTracking();
  const { updateSubView, subView } = useCourseState();

  updateSubView();
  if (subView.value !== CourseSubViewType.Course) {
    return;
  }

  const pathToTrackingValueMap: Record<string, CourseLandingPageSourceValues | null> = {
    '/dashboard': CourseLandingPageSourceValues.DiscoveryPage,
    '/courses': CourseLandingPageSourceValues.CourseLibrary,
    [to.path]: CourseLandingPageSourceValues.Direct,
  };

  const trackingValue = pathToTrackingValueMap[from.path] || null;
  if (!trackingValue) {
    return;
  }

  dispatchTrackingEvent(PageEvents.CourseLandingPageViewed, {
    user: {
      plan_type: user.value?.subscription?.type,
    },
    user_specialty: user.value?.specialty,
    course_id: to.params.courseId,
    landing_page_source: trackingValue,
  });
});